import React from "react";

import Gallery from "../../components/_Gallery";
//@ts-ignore
export default function Home() {
  return (
    <div className="home-page">
      <Gallery />
    </div>
  );
}
