import { useState, useEffect } from "react";
import "./_Footer.scss";
export default function Footer() {
  return (
    <div id="footer" className="nh-row">
      <div id="footer-row-6" className="nh-row  footer-padding ">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="footer-information mainFooter">
                <div className="item item-1">
                  <span className="ic-1 ic-info"></span>
                  <p className="title">Thông tin liên hệ</p>
                  <div className="hotLine text-center">
                    <div className="logo-f">
                      <img src="https://tueanh.vn/uploads/plugin/custom_img/2022-04-29/1651204213-1256063192-custom.png" />
                    </div>
                    <div className="company-f">
                      <span>CÔNG TY TNHH ĐẦU TƯ TUỆ ANH HEALTH CARE</span>
                      <span>
                        PHÒNG KHÁM MẮT TUỆ ANH - KÍNH MẮT, NHÀ THUỐC TUỆ ANH
                      </span>
                    </div>
                  </div>
                  <div className="itemSub" style={{ paddingTop: 0 }}>
                    <p className="address">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                      104 Phố Huế, quận Hai Bà Trưng, Hà Nội
                    </p>
                    <p className="phone">
                      <a href="tel:0931108663" title="0931108663">
                        093.110.8663 - 038.483.3663 - 024.7776.6656
                      </a>
                    </p>
                    <p className="mail">
                      <a href="mailto:info@tueanh.vn">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        info@tueanh.vn{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="footer-time-work mainFooter">
                <div className="item item-2">
                  <span className="ic-1 ic-time"></span>
                  <p className="title">Thời gian làm việc</p>
                  <ul className="list-unstyled">
                    <li>
                      <i className="fa fa-clock-o" aria-hidden="true"></i>Thời
                      gian làm T2 - T6: Từ <span>8h00</span> đến{" "}
                      <span>21h00</span>
                    </li>
                    <li>
                      <i className="fa fa-clock-o" aria-hidden="true"></i>Thời
                      gian làm T7 - CN: Từ <span>8h00</span> đến{" "}
                      <span>17h00</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-work mainFooter">
                <div className="item item-3">
                  <p className="title-dkkd">
                    {" "}
                    TNHH Đầu Tư Tuệ Anh Healthcare - Phòng Khám Mắt Tuệ Anh -
                    Kính Mắt, Nhà Thuốc Tuệ Anh
                  </p>
                  <p>
                    {" "}
                    Số đăng kí kinh doanh 0108577716 do Sở Kế hoạch và Đầu tư
                    Thành phố Hà Nội cấp lần đầu ngày 08 tháng 01 năm 2019 (đăng
                    ký thay đổi lần thứ 1: ngày 09 tháng 03 năm 2021). Địa điểm
                    kinh doanh: Số 104 Phố Huế, phường Nguyễn Du, Quận Hai Bà
                    Trưng, Thành phố Hà Nội, Việt Nam
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className="item_block"
                data-action="contact/ContactBlock/contactBlock/76"
              >
                <div
                  id="form-contact"
                  className="mainFooter wide footer-contact content-contact reservationForm "
                >
                  <div className="item item-3">
                    <span className="ic-1 ic-form-contact"></span>
                    <p className="title">Đặt lịch khám</p>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="content-primary wow zoomIn">
                          <form
                            id="frm-send-contact-1638130655"
                            className="frm-required form-contact"
                            method="POST"
                            action="https://tueanh.vn/contact/ContactBlock/sendRequest"
                          >
                            <div className="row">
                              <div className="form-group hidden">
                                <input
                                  className="form-control required"
                                  type="hidden"
                                  name="title"
                                  value="Đặt lịch khám"
                                  aria-required="true"
                                />
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <span className="icon">
                                    <i
                                      className="fa fa-user"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <input
                                    className="form-control required"
                                    type="text"
                                    name="full_name"
                                    placeholder="Họ và tên"
                                    aria-required="true"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <span className="icon">
                                    <i
                                      className="fa fa-mobile"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <input
                                    className="form-control required"
                                    type="phone"
                                    name="phone"
                                    placeholder="Số điện thoại"
                                    aria-required="true"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <span className="icon">
                                    <i
                                      className="fa fa-envelope"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <input
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <span className="icon">
                                    <i
                                      className="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <input
                                    required
                                    title="Ngày tới khám không được rỗng"
                                    id="datepicker"
                                    type="text"
                                    name="content[contact_date]"
                                    className="form-control"
                                    placeholder="Ngày tới khám"
                                    aria-required="true"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group form-content">
                                  <span className="icon">
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <textarea
                                    className="form-control"
                                    name="content[contact_content]"
                                    placeholder="Mong muốn"
                                  ></textarea>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group list-button">
                                  <div className="btn-content">
                                    <button
                                      type="submit"
                                      className="btn btn-submit"
                                    >
                                      <span className="name">Đặt lịch</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer-row-42" className="nh-row  footer-between ">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="item_block"
                data-action="footer/FooterBlock/footerFangage/59"
              >
                <div className="menu-footer-vertical box-ft">
                  <h4 className="title-menu-ft"> Kết nối </h4>
                  <div className="icon-social">
                    <a
                      href="https://www.facebook.com/mattueanh/"
                      target="_blank"
                      className="item-social"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCid_WmgBb8CySYX992JbecA/featured"
                      target="_blank"
                      className="item-social"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                    <a href="#" target="_blank" className="item-social">
                      <i className="fa fa-google-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="#" title="Chính sách &amp; Quy định chung">
                      Chính sách &amp; Quy định chung
                    </a>
                  </li>
                  <li>
                    <a href="#" title="Quy định và hình thức thanh toán">
                      Quy định và hình thức thanh toán
                    </a>
                  </li>
                  <li>
                    <a
                      href="chinh-sach-bao-mat-thong-tin-khach-hang-phong-kham-mat-tue-anh"
                      title="Chính sách bảo mật thông tin cá nhân"
                    >
                      Chính sách bảo mật thông tin cá nhân
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-copy">
                <ul>
                  <li>Copyright © 2017 Phòng khám mắt Tuệ Anh</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer-row-76" className="nh-row   ">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="scroll-top">
                <a href="#" id="return-to-top" style={{ display: "inline" }}>
                  <img src="https://tueanh.vn/uploads/plugin/custom_img/2019-04-13/1555122112-983090971-custom.jpg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer-row-81" className="nh-row   ">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="contactLeft">
                <div className="item phone">
                  <a className="icon" href="tel:0931108663">
                    <div className="quick-alo-ph-circle hvr-icon-pulse"></div>
                    <i className="fa fa-mobile" aria-hidden="true"></i>
                  </a>
                  <div className="info">
                    <div className="wrapper">
                      <span>Hotline</span>
                      <a href="tel:0931108663" title="">
                        093.110.8663 - 024.7776.6656
                      </a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <a
                    className="icon"
                    target="_blank"
                    href="https://www.facebook.com/mattueanh/"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <div className="info">
                    <div className="wrapper">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/mattueanh/"
                        title=""
                      >
                        Fanpage Facebook
                      </a>
                    </div>
                  </div>
                </div>
                <div className="item calendar">
                  <a
                    data-scroll=""
                    className="icon diaChi"
                    href="#form-contact"
                  >
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                  </a>
                  <div className="info">
                    <div className="wrapper">
                      <span>Lịch khám</span>
                      <a
                        href="#form-contact"
                        title=""
                        className="scroll diaChi"
                      >
                        Đặt Lịch Khám
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
