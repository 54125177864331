import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { slugify } from "../../lib/StrUtils";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CameraDialog from "./CameraDialog";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Lightbox, { SlideImage } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { useApiData } from "../../store/ApiDataProvider";
import Gallery from "../../components/_Gallery";
import CameraIcon from "../../components/SvgIcon/CameraIcon";
import { Rating } from "react-simple-star-rating";
import { formatNumber } from "../../lib/NumberUtils";
// import { useGlassez } from "../../store/GlassezProvider";
import "./GlassezDetail.scss";
// import AddToCart from "./AddToCart";
import { isMobile } from "../../lib/Utils";
import { Box, Tab, Tabs } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function GlassezDetail() {
  const navigate = useNavigate();
  const [ratingValue, setRatingValue] = useState(0);
  const [openGlassezImgsDialog, setOpenGlassezImgsDialog] = useState(false);
  const { apiData } = useApiData();
  const [glassez, setGlassez] = useState(Object);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const thumbnailsRef = useRef(null);
  const [openCamera, setOpenCamera] = useState(false);
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [glassezs, setGlassezs] = useState([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const supportStreamCamera = () => {
    return (
      window.location.protocol.indexOf("https:") === 0 ||
      window.location.host.indexOf("localhost") === 0
    );
  };
  let [price, setPrice] = useState(0);
  const refItemLimit = 6;

  useEffect(() => {
    (apiData.glassezs as []).filter((item: any) => {
      if (item.id == id) {
        setGlassez(item);
        setRatingValue(item.star);
        setPrice(item.price);
        let allGlassezs: any = [];
        (apiData.glassezs as []).filter((item: any) => {
          if (item.face_name == item.face_name) {
            allGlassezs.push(item);
          }
        });

        setGlassezs(getRandomItems(allGlassezs, refItemLimit));
      }
    });
  }, [apiData, id]);



  function getRandomItems<T>(list: T[], count: number): T[] {
    // Nếu số lượng muốn lấy lớn hơn hoặc bằng số phần tử trong danh sách,
    // chỉ cần trả về danh sách đầy đủ
    if (count >= list.length) {
      return list;
    }

    // Sao chép danh sách để tránh ảnh hưởng đến danh sách gốc
    const copiedList = [...list];

    // Tạo một mảng để lưu trữ kết quả
    const randomItems: T[] = [];

    // Lặp để lấy số lượng phần tử muốn lấy
    for (let i = 0; i < count; i++) {
      // Nếu danh sách còn phần tử
      if (copiedList.length > 0) {
        // Lấy một phần tử ngẫu nhiên từ danh sách
        const randomIndex = Math.floor(Math.random() * copiedList.length);
        const selectedItem = copiedList.splice(randomIndex, 1)[0];

        // Thêm phần tử đã chọn vào mảng kết quả
        randomItems.push(selectedItem);
      } else {
        // Trong trường hợp danh sách đã hết phần tử, thoát khỏi vòng lặp
        break;
      }
    }

    return randomItems;
  }


  const itemToLightboxData = (): SlideImage[] => {
    if (glassez.imgs) {
      let a = glassez.imgs.map((src: string) => {
        return { ...glassez, src: src };
      });
      // a.push(a[0])
      return a;
    }

    return [];
  };

  return glassez ? (
    <div className="glassez-detail-page">
      <div className="glassez-container container">

        <div className="glassez-images">
          <Carousel showArrows={false} showIndicators={false}>
            {itemToLightboxData().map((si, i) => (
              <div key={`crs-${i}`}>
                <img
                  src={si.src}
                  onClick={() => {
                    setSelectedImageIndex(0);
                    setOpenGlassezImgsDialog(true);
                  }}
                  key={"img-item-0"}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="glassez-info">

          <div className="head-border">
            <h2>
              {glassez.title}
            </h2>
            <p>Mã sản phẩm: <b>{glassez.code}</b></p>
          </div>

          <div className="price">
            <b>{formatNumber(price)}đ</b>
          </div>
          <div className="foot-area">
            {supportStreamCamera() &&
              glassez.imgs &&
              glassez.imgs.length > 0 && (
                <Button
                  className="btn btn-primary"
                  variant="outlined"
                  onClick={() => {
                    setOpenCamera(true);
                    document
                      .getElementById("camera-dialog")
                      ?.classList.remove("hide");
                  }}
                >
                  Thử kính ngay
                </Button>
              )}
            <a href="tel:0931108663" className="btn btn-primary">
              Tư vấn ngay
            </a>
          </div>
        </div>
      </div>
      <div className="detail container">
        <Box sx={{ mt: 8 }}>
          <Tabs value={value} onChange={handleChange} className="desc-tabs">
            <Tab label="Mô tả sản phẩm" {...a11yProps(0)} />
            <Tab label="Đánh giá sản phẩm" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <div
          role="tabpanel"
          hidden={value !== 0}
          id={`simple-tabpanel-0`}
          aria-labelledby={`simple-tab-0`}
        >
          {value === 0 && (
            <Box sx={{ p: 3 }}>
              <div dangerouslySetInnerHTML={{ __html: glassez.description }} />
            </Box>
          )}
        </div>
      </div>
      <div className="ref-items container">
        <div className="title">Sản phẩm tương tự</div>
        <div className="items">

          {glassezs.map((item: any, index) => {
            return (
              <div
                key={"gallery-items" + index}
                className="item col-sm-3"
                onClick={() => {
                  navigate("/glasses/" + slugify(item.title) + "/" + item.id);
                }}
              >
                <div className="img">
                  <img src={item.src} />
                </div>
                <div className="foot-area">
                  <div className="title">
                    {item.title}
                  </div>
                  <div className="price">{formatNumber(item.price)}đ</div>

                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Lightbox
        className={"lightbox-dialog"}
        styles={{
          container: { backgroundColor: "#ccc" },
          // root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" },
        }}
        plugins={[Captions, Thumbnails, Zoom]}
        thumbnails={{ ref: thumbnailsRef }}
        close={() => {
          setOpenGlassezImgsDialog(false);
          document.querySelector("body")?.removeAttribute("style");
        }}
        index={selectedImageIndex}
        open={openGlassezImgsDialog}
        slides={itemToLightboxData()}
        toolbar={{
          buttons: ["close"],
        }}
      />
      <CameraDialog
        openCamera={openCamera}
        setOpenCamera={setOpenCamera}
        glassez={glassez}
      />
    </div>
  ) : (
    <></>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
